import React from "react";
import "./Explore.css";
import {Image, Dropdown, ButtonGroup, Button, Nav} from 'react-bootstrap';
import { NavLink, Link } from "react-router-dom";
import ExploreTab from './images/explore-tab.png'

const Explore = (data) => {
    
    const mobileButtons = () => {
        if (window.innerWidth > 500) {
        return (
            <React.Fragment>
            <div className="col-sm-6 col-xs-12 button__col--left">
                        <ButtonGroup className="explore-btn-right"> 
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-custom-1" variant="explore">
                                <Button variant="explore">Desired Education Level</Button>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="explore-dd-background">
                                <Dropdown.Item href="/education/1" className="explore-dd-background">High School</Dropdown.Item>
                                <Dropdown.Item href="/education/2" className="explore-dd-background">2-Year School</Dropdown.Item>
                                <Dropdown.Item href="/education/3" className="explore-dd-background">4-Year School</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </ButtonGroup>
            </div>
            <div className="col-sm-6 col-xs-12 button__col--right">
                        <ButtonGroup className="">
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-custom-2" variant="explore">
                                <Button className="justify-content-left" variant="explore">Your Career Interests</Button>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="explore-dd-background">
                            {data.careerInfo.map((item, i) => {
                                            return <Dropdown.Item href={`/career/${item.id}`} className="explore-dd-background" key={i} value={item.sector}>{item.sector}</Dropdown.Item>
                                        })}
                            </Dropdown.Menu>
                        </Dropdown>
                        </ButtonGroup>
            </div>
            </React.Fragment>
            )} else {
                return (
                <React.Fragment>
                <div className="row row__button">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <ButtonGroup className="explore-btn-right"> 
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-custom-1" variant="explore">
                                <Button variant="explore">Desired Education Level</Button>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="explore-dd-background">
                                <Dropdown.Item href="/education/1" className="explore-dd-background">High School</Dropdown.Item>
                                <Dropdown.Item href="/education/2" className="explore-dd-background">2-Year School</Dropdown.Item>
                                <Dropdown.Item href="/education/3" className="explore-dd-background">4-Year School</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </ButtonGroup>
                    </div>
                </div>
                    <div className="row">
                        <div className="col-xs-12 mb-2">
                        <ButtonGroup className="">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-custom-1" variant="explore">
                                    <Button className="justify-content-left" variant="explore">Your Career Interests</Button>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="explore-dd-background">
                                {data.careerInfo.map((item, i) => {
                                            return <Dropdown.Item href={`/career/${item.id}`} className="explore-dd-background" key={i} value={item.sector}>{item.sector}</Dropdown.Item>
                                        })}
                                </Dropdown.Menu>
                            </Dropdown>
                            </ButtonGroup>
                        </div>
                    </div>
                </React.Fragment>
                )
            }

        }
    
    
    return(
        <div className="explore__wrapper">
            <div className="explore-tab">
                <Image className="explore-tab__image" src={ExploreTab} alt="" />
            </div>
            <div className="explore-background">
                <div className="container">
                    <div className="explore-padding justify-content-center row col-12">     
                        {mobileButtons()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Explore;