import React from "react";
import './ResourcesPage.css';
import Explore from "../shared/explore/Explore";
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import coach from './images/Career_Coach_Online.png';
import JA from './images/JA_My_Way.png';
import invest from './images/Invest_next.png';
import nerdWallet from './images/NerdWallet.png';
import payScale from './images/PayScale.png';
import nextMove from './images/NextMove.png';
import careerOneStop from './images/Career_One_Stop.png';
import bankRate from './images/BankRate.png';



const ResourcesPage = (data) => (
    <React.Fragment>
        <div className="resources-color-div">
        </div>
        <div>
            {data.dropDownInfo.tiles ? <Explore careerInfo = {data.dropDownInfo.tiles}/> : null}
        </div>
        <div className="resource-page__wrapper pb-5 text-center">
            <div className="resources-header text-center pt-5 pb-5 pl-3 pr-3">
                <div className="header__headline">Resources</div>
                <div className="header__copy">Take advantage of these online tools to help you find your way.</div>
            </div>
            <div className="row justify-content-center">
                <Card className="card__resource card__full-height">
                    <Card.Body>
                        <Card.Title className="text-center"><img src={coach} className="resource-card-image mx-auto"/></Card.Title>
                        <Card.Text className="resource-text resource-card--home">
                        <span className="resource-title">
                        John Tyler Career Coach <br /></span>
                        <br />
                            <span className="resource-copy">Explore your career and <br />
                                educational interests.<br /></span>
                                <br />
                            <span className="resource-cta">FIND YOUR CAREER</span>
                        </Card.Text>
                            <a target="_blank" rel="noopener noreferrer"href="https://jtcc.emsicc.com/"><Button variant="view">View</Button></a>
                    </Card.Body>
                </Card>
                <Card className="card__resource card__full-height">
                    <Card.Body>
                    <Card.Title className="text-center"><img src={JA} className="resource-card-image"/></Card.Title>
                        <Card.Text className="resource-text resource-card--home">
                            <span className="resource-title">
                            JA My Way<br /></span>
                            <br />
                            <span className="resource-copy">Get tips and tools to hop on the<br />
                                career fast track.<br /></span>
                            <br />
                        <span className="resource-cta">TIPS AND TOOLS</span>
                        </Card.Text>
                            <a href="https://www.jamyway.org/" target="_blank" rel="noopener noreferrer"><Button variant="view">View</Button></a>
                    </Card.Body>
                </Card>
                <Card  className="card__resource card__full-height">
                    <Card.Body>
                        <Card.Title className="text-center"><img src={invest} className="resource-card-image"/></Card.Title>
                        <Card.Text className="resource-text resource-card--home">
                            <span className="resource-title">
                            Invest in What's Next<br /></span>
                            <br />
                            <span className="resource-copy">Discover your interests.<br />
                            Budget and evaluate your plan.<br /></span>
                            <br />
                            <span className="resource-cta">COST CALCULATOR</span>
                        </Card.Text>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.investinwhatsnext.org/"><Button variant="view">View</Button></a>
                    </Card.Body>
                </Card>
            </div>
            <div className="row justify-content-center">
                <Card className="card__resource card__full-height">
                    <Card.Body>
                        <Card.Title className="text-center"><img src={careerOneStop} className="resource-card-image mx-auto"/></Card.Title>
                        <Card.Text className="resource-text resource-card--home">
                        <span className="resource-title">
                        Career One Stop<br /></span>
                        <br />
                            <span className="resource-copy">Your source for career<br />
                            exploration, training and jobs.<br /></span>
                                <br />
                            <span className="resource-cta">FIND YOUR CAREER</span>
                        </Card.Text>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.careeronestop.org/"><Button variant="view">View</Button></a>
                    </Card.Body>
                </Card>
                <Card className="card__resource card__full-height">
                    <Card.Body>
                        <Card.Title className="text-center"><img src={nextMove} className="resource-card-image mx-auto"/></Card.Title>
                        <Card.Text className="resource-text resource-card--home">
                        <span className="resource-title">
                        My Next Move<br /></span>
                        <br />
                            <span className="resource-copy">Browse over 900 career options<br />
                            based on industry and interest.<br /></span>
                                <br />
                            <span className="resource-cta">DEFINE YOUR INTEREST</span>
                        </Card.Text>
                            <a href="https://www.mynextmove.org/" target="_blank" rel="noopener noreferrer"><Button variant="view">View</Button></a>
                    </Card.Body>
                </Card>
                <Card className="card__resource card__full-height">
                    <Card.Body>
                        <Card.Title className="text-center"><img src={bankRate} className="resource-card-image mx-auto"/></Card.Title>
                        <Card.Text className="resource-text resource-card--home">
                        <span className="resource-title">
                        Bankrate<br /></span>
                        <br />
                            <span className="resource-copy">Get the latest advice on careers,<br />
                            job trends and salaries.<br /></span>
                                <br />
                            <span className="resource-cta">COST CALCULATOR</span>
                        </Card.Text>
                            <a href="https://www.bankrate.com/" target="_blank" rel="noopener noreferrer"><Button variant="view">View</Button></a>
                    </Card.Body>
                </Card>
            </div>
            <div className="row justify-content-center">
                <Card className="card__resource card__full-height">
                    <Card.Body>
                        <Card.Title className="text-center"><img src={nerdWallet} className="resource-card-image mx-auto"/></Card.Title>
                        <Card.Text className="resource-text resource-card--home">
                        <span className="resource-title">
                        Nerd Wallet<br /></span>
                        <br />
                            <span className="resource-copy">Gain insight on how to start<br />
                            and advance your career.<br /></span>
                                <br />
                            <span className="resource-cta">COST CALCULATOR</span>
                        </Card.Text>
                        <a target="_blank" href="https://www.nerdwallet.com/" rel="noopener noreferrer"><Button variant="view">View</Button></a>
                    </Card.Body>
                </Card>
                <Card className="card__resource card__full-height">
                    <Card.Body>
                        <Card.Title className="text-center"><img src={payScale} className="resource-card-image mx-auto"/></Card.Title>
                        <Card.Text className="resource-text resource-card--home">
                        <span className="resource-title">
                        PayScale<br /></span>
                        <br />
                            <span className="resource-copy">Get career news and advice<br />
                            with a dash of humor.<br /></span>
                                <br />
                            <span className="resource-cta">COST CALCULATOR</span>
                        </Card.Text>
                        <a target="_blank" href="https://www.payscale.com/" rel="noopener noreferrer"><Button variant="view">View</Button></a>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </React.Fragment>
);

export default ResourcesPage;