import React, { useState } from "react";
import "./Cluster-tile-edu.css";
import { Image } from "react-bootstrap";
import { Card } from "react-bootstrap";
import {Row, Col} from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import round_plus from "./images/Round_Plus.png";


const ClusterTileEdu = (data) => { 
   

    const [hovered, setHovered] = useState(false);
    const toggleHover = () => setHovered(!hovered);

   
    return(
        <React.Fragment>
            <div className="" id="cardContent">
                <Card className="clusterCardTile" onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                    <Accordion>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            <div className="row cluster-row">
                                <div className="col-10">
                                <div className="row">{data.cluster}</div>
                                </div>
                                <div className="col-2">
                                    <Image src={round_plus} fluid></Image>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className="cluster-tile__card-body">
                                <Row>
                                    <Col xs={4} sm={{ span: 4 }}  className="cluster-tile__header">Sample Job</Col>
                                    <Col xs={4} sm={{ span: 4 }}  className="cluster-tile__header">Median Pay</Col>
                                    <Col xs={4}  sm={{ span: 4}}  className="cluster-tile__header">Job Openings</Col>
                                </Row>
                            {data.jobInfo.map((item, i) => {
                            return (
                                <Row key={i} value={item} className="cluster-tile__row">
                                     <Col xs={4} sm={{ span: 4 }} className="cluster-tile__column pr-2 pt-3">
                                        <div>
                                            <div className="tile__job-title" key={i} value={item.jobTitle}>{item.jobTitle}</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} sm={{ span: 4 }} className="cluster-tile__column pr-2 pt-3">
                                        <div>
                                            <div className="tile__job-salary" key={i} value={item.jobSalary}>{item.jobSalary}</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} sm={{ span: 4}} className="cluster-tile__column pr-2 pt-3">
                                       <div>
                                           <div className="tile__job-openings" key={i} value={item.jobOpenings}>{item.jobOpenings}</div>
                                        </div>
                                    </Col>
                                </Row>);
                                })
                            }        
                            <div className="tile-separator mt-4" />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                </Card>
            </div>
        </React.Fragment>
    );
}

export default ClusterTileEdu;
