import React, { useState, useEffect }  from "react";
import Explore from "../shared/explore/Explore";
import ClusterTile from "../shared/cluster-tile/Cluster-tile.js";
import ClusterTileEdu from "../shared/cluster-tile-edu/Cluster-tile-edu.js";
import Resource from "../shared/resource/Resource";
import GetStarted from "../shared/getstarted/GetStarted";
import HeroCluster from "../shared/heroCluster/HeroCluster";
import axios from "axios";
import "./EducationPage.css";
import { Image, Col, Row } from "react-bootstrap";

const EducationPage = (data) => {
    const [] = useState(false);
    const [jobClusterData, setJobClusterData] = useState({});
    const [educationClusterData, setEducationClusterData] = useState({});
    const [jobInfoData, setJobInfoData] = useState({});
    const [loadingInd, setLoadingInd] = useState({showLoading: true});
    const [loadingIndSector, setLoadingIndSector] = useState({showLoadingSector: true});

    const careerUrl = `../tile-text.json`;   
    const educationUrl = `../education-text.json`; 
    const jobUrl = `../job-info.json`;  

     let getCareerjobClusters = () => {
         return axios.get(careerUrl);
     }

     let getEducationCluster = () => {
         return axios.get(educationUrl);
     }

     let getJobs = () => {
        return axios.get(jobUrl);
    }

     useEffect(() => {
         axios.all([getCareerjobClusters(), getEducationCluster(), getJobs()])
            .then(axios.spread(function (career, education, jobs) {
                const jobClusters = career.data;
                const educationCluster = education.data;
                const indJobs = jobs.data;

                setJobClusterData({ jobClusters });
                setEducationClusterData({ educationCluster });
                setJobInfoData({indJobs});
                setLoadingInd({showLoading: false});
                setLoadingIndSector({showLoadingSector: false});  
         }));
     }, []);

     let careerData = [];
     let educationData = [];
     let jobData = [];
    
     let filterResponse = (rawData, rawJobData, loading) => {
         if (loading.showLoading === true) {
             return (
                educationData = [
                    {
                        "id" : "0",
                        "level" : "Loading",
                        "image" : "Loading",
                        "clusterText" : "Loading",
                        "didYouKnow" : {
                            "number" : "Loading",
                            "primaryText": "Loading",
                            "secondaryText" : "Loading"
                        },
                    }
                ],
                jobData = [
                    { 
                        "id": "0",
                        "sector" : "Loading",
                        "topJobs" : [
                                {
                                    "jobTitle": "Loading...",
                                    "jobLevel": "Loading...",
                                    "jobDescription": "Loading...",
                                    "jobSalary":"Loading...",
                                    "jobOpenings": "Loading..."
                                }]
                   }
               ]
             )
         } else {
             return (
                 educationData = rawData.educationCluster.filter(cluster => cluster.id === data.match.params.educationId),
                 jobData = rawJobData
                )
            } 
        }

     filterResponse(educationClusterData, jobInfoData, loadingInd);
    
    return(
    <React.Fragment>
        <div className="nav-background"></div>
        {data.dropDownInfo.tiles ? <Explore careerInfo = {data.dropDownInfo.tiles}/> : null}
        <Row className="">
        <Col xs={12} sm={12} md={8} className="pt-5 pb-5 pl-5">
            <Row>
                <Col xs={12} sm={12} md={12} lg={3} className="hero-icon__wrapper">
                    <Image className="hero-icon pr-5" src={ educationData[0].image } alt="" />
                </Col>
                <Col xs={12} sm={12} md={12} lg={9} className="hero-copy pl-5 pr-5">
                    <h2 className="hero-copy__headline">{ educationData[0].level }</h2>
                    <p className="hero-copy__text">
                        { educationData[0].clusterText }
                    </p>  
                </Col>
            </Row>
        </Col>
        <Col className="hero-data pt-5 pb-5" xs={12} sm={12} md={4}>
            <p className="hero-data__headline">DID YOU KNOW?</p>
            <Image src=""></Image>
            <p className="hero-data__salary pt-4">{ educationData[0].didYouKnow.number }</p>
            <p className="hero-data__salary-primary-text pt-3">{ educationData[0].didYouKnow.primaryText }</p>
            <p className="hero-data__salary-secondary-text pt-3">{ educationData[0].didYouKnow.secondaryText }</p>
        </Col>
    </Row>
        <div className="education-background pb-5">
            <div className="pt-5 pb-5 home-text-banner">
                <h2>View Possible Careers</h2>
                <p>Look down. See anything that interests you?
                <br />
                Click on a job field and go from there.</p>
            </div>
            <div className="tile-wrapper">
                <Row className="tile-row">
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[0]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[1]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                </Row>
                <Row className="tile-row">
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[2]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[3]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                </Row>
                <Row className="tile-row">
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[4]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[5]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                </Row>
                <Row className="tile-row">
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[6]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[7]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                </Row>
                <Row className="tile-row">
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[8]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[9]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                </Row>
                <Row className="tile-row">
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[10]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[11]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                </Row>
                <Row className="tile-row">
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[12]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[13]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                </Row>
                <Row className="tile-row">
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[14]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                    { jobClusterData.jobClusters ? <ClusterTile cluster={jobClusterData.jobClusters[15]} topJobs={jobData} edu={data.match.params.educationId} loading={loadingIndSector}/> : null }
                </Row>
                <Row>
                    <Col className="text-center pt-4"><b>The positions displayed may require additional certifications or licenses.</b></Col>
                </Row>
            </div>
        </div>
        
        <GetStarted />
        <Resource />
    </React.Fragment>
    );
};

export default EducationPage;