import React, {  } from "react";
import Hero from "../shared/hero/Hero";
import Tile from "../shared/tile/Tile";
import Explore from "../shared/explore/Explore";
import Resource from "../shared/resource/Resource";
import "./HomePage.css";


const HomePage = (data) => {
     
    return(

    <React.Fragment>
        <Hero />
        {data.tileInfo.tiles ? <Explore careerInfo = {data.tileInfo.tiles}/> : null}
        <div className="main-background">
            <span className="mt-5 pb-5 home-text-banner">
                <h2>Find Your Path</h2>
                <p className="banner__copy">Building a career and enjoying your future starts with learning your options.  <br />
                Click on an interest below and discover your possibilities.</p>
            </span>
             <div className="row justify-content-center">
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[0]}/> : null}
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[1]}/> : null}
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[2]}/> : null}
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[3]}/> : null}
            </div>       
            <div className="row justify-content-center">
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[4]}/> : null}
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[5]}/> : null}
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[6]}/> : null}
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[7]}/> : null}
            </div>
            <div className="row justify-content-center">
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[8]}/> : null}
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[9]}/> : null}
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[10]}/> : null}
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[11]}/> : null}
            </div>
            <div className="row justify-content-center">
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[12]}/> : null}
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[13]}/> : null}
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[14]}/> : null}
                {data.tileInfo.tiles ? <Tile tile={data.tileInfo.tiles[15]}/> : null}
            </div>
        </div>
        <Resource />
    </React.Fragment>
    );
};

export default HomePage;