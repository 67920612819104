import React from "react";
import { Image, Button, Col } from "react-bootstrap";
import "./Footer.css";
import Chamber from './images/Chamber_Logo.png';
import DateStamp from './images/Hire_Ed_date.png';
import Twitter from "./images/twitter.png";
import Facebook from "./images/facebook.png";
import Youtube from "./images/youtube.png";
import Linkedin from "./images/linkedin.png";
import Insta from "./images/instagram.png";

const Footer = () => {
    let scrollTop = () => {
        window.scrollTo({
            top:0
        })
    }

    return(
    <div className="footer-background mt-0">
        <div className="ml-4 mr-4">
            <div className="row pt-4 pb-4 text-center footer__row-1">
                <Col xs={12} sm={4} md={4} lg={4} className="my-auto copyright">
                    <Image src={DateStamp}></Image>    
                </Col>
                <Col xs={12} sm={4} md={4} lg={4} className="my-auto">
                    <div className="mx-auto text-center">
                        <Image src={Chamber}></Image>
                    </div>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4} className="my-auto back-to-top">
                    <Button className="" variant="back" onClick={scrollTop}>BACK TO TOP</Button>
                </Col>
            </div>
            <div className="row">
                <hr className="footer-divider w-100" />
            </div>
        <div className="row pt-3 footer__second-row footer__row-2">
            <Col>
                <ul xs={12} sm={12} md={8} lg={8} className="nav footer__icons">
                    <li className="nav-item active">
                        <a className="nav-link footer-link-nav header-link-text" href="/">Home <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item active ml-2">
                        <a className="nav-link footer-link-nav header-link-text" href="/about">About <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item active ml-2">
                        <a className="nav-link footer-link-nav header-link-text" href="/resources">Resources <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item active ml-2">
                        <a className="nav-link footer-link-nav header-link-text" href="https://www.chamberrva.com/legal/">Privacy Policy <span className="sr-only">(current)</span></a>
                    </li>           
                </ul>
            </Col>
            <Col>
                <div xs={12} sm={12} md={4} lg={4} className="footer__social">
                    <div className="float-right">
                    <a href="https://www.facebook.com/ChamberRVA/" target="_blank"><Image src={Facebook} /></a>
                    <a href="https://www.linkedin.com/company/chamberrva" target="_blank"><Image className="ml-4" src={Linkedin}/></a>
                    <a href="https://twitter.com/chamberrva" target="_blank"><Image className="ml-4" src={Twitter}/></a>
                    <a href="https://www.youtube.com/channel/UC_RJj5Dcic98ofmVnCG-h6g" target="_blank"><Image className="ml-4" src={Youtube}/></a>
                    <a href="https://www.instagram.com/chamberrva/" target="_blank"><Image className="ml-4" src={Insta}/></a>
                    </div>
                </div>
            </Col>    
        </div>
        </div>
    </div>
    )
};

export default Footer;
