import React from "react";
import "./GetStarted.css";
import { Row, Col } from "react-bootstrap";
import talk from './images/get-started-talk.png';
import apply from './images/get-started-apply.png';
import tech from './images/get-started-tech.png';
import interests from './images/get-started-interests.png';

const GetStarted = () => (
        <div className="pt-5 pb-5 get-started wrapper">
            <div className="pb-4 get-started-text">
                <h2 className="get-started__header">Get Started</h2>
                <p className="get-started__copy">There are lots of in-demand jobs just waiting to be filled.
                <br/>
                Prepare yourself today to join the workforce of tomorrow.</p>
            </div>
            <Row>
                <Col>
                    <div className="get-started-image-wrapper">
                        <img className="get-started-image" src={talk} alt="" />
                    </div>
                    <h3 className="get-started__col-header">Talk to someone
                    <br/>
                    you know.</h3>
                    <p className="get-started__col-copy">Discuss your options with
                    <br/>
                    family, friends and counselors.</p>
                </Col>
                <Col>
                    <div className="get-started-image-wrapper">
                        <img className="get-started-image" src={tech} alt="" />
                    </div>
                    <h3 className="get-started__col-header">Explore tech classes
                    <br/>
                    and specialty centers.</h3>
                    <p className="get-started__col-copy">Gain hands-on experience
                    <br/>
                    for in-demand jobs.</p>
                </Col>
                <Col>
                    <div className="get-started-image-wrapper">
                        <img className="get-started-image" src={interests} alt="" />
                    </div>
                    <h3 className="get-started__col-header">Discover your interests
                    <br/>
                    and passions</h3>
                    <p className="get-started__col-copy">Take this career quiz to
                    <br/>
                    discover what excites you.</p>
                </Col>
                <Col>
                    <div className="get-started-image-wrapper">
                        <img className="get-started-image" src={apply} alt="" />
                    </div>    
                    <h3 className="get-started__col-header">Apply for volunteer work
                    <br/>
                    and internships</h3>
                    <p className="get-started__col-copy">Network, learn from a mentor
                    <br/>
                    and get on-the-job training.</p>
                </Col>
            </Row>
        </div>
    );

export default GetStarted;