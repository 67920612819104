import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import Burnt from "./images/Hero_1_BS.png";
import Fuscia from "./images/Hero_1_MF.png";
import Shamrock from "./images/Hero_1_Shamrock.png";
import { Image } from "react-bootstrap";
import './Hero.css';


const Hero = () => (
<div>
    <Carousel>
        <Carousel.Item>
            <Image
            className="d-block w-100"
            src={ Burnt }
            alt="Career Goals"
            />
        </Carousel.Item>
        <Carousel.Item>
            <Image
            className="d-block w-100"
            src={ Fuscia }
            alt="Career Goals"
            />
        </Carousel.Item>
        <Carousel.Item>
            <Image
            className="d-block w-100"
            src={ Shamrock }
            alt="Career Goals"
            />
        </Carousel.Item>
    </Carousel>
</div>
);

export default Hero;