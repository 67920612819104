import React from "react";
import Explore from "../shared/explore/Explore";
import Resource from "../shared/resource/Resource";
import aboutCopy from "./images/about_copy.png";
import "./AboutPage.css";
import { Col, Image, Row } from "react-bootstrap";
import heroLeft from "./images/whats-hireed.jpg";
import heroRight from "./images/whats-hireed-right.jpg";

const AboutPage = (data) => {
  
  return(
  <React.Fragment>
    <div className="about-color-div">
    </div>
  
    <div>
      {data.dropDownInfo.tiles ? <Explore careerInfo = {data.dropDownInfo.tiles}/> : null}
    </div>
  
    <div className="about__wrapper">
        <Row>
            <Col lg={7} med={7} sm={12} xs={12} className="about__col-left">
                <h2 className="about-headline pt-5 pl-5">
                    What’s HireEd?
                </h2>
                <div className="about-copy pl-5 pr-5 pb-5">
                HireEd is an initiative that introduces RVA students to the many pathways they can take to land careers and real-world jobs in our region. Some of those paths lead to college, some involve certificates, and still others include on-the-job training. Regardless of which course they choose, the best way for students to make their next move is to get all the information they need to succeed. That's where HireEd comes in.</div>
            </Col>
            <Col lg={5} med={5} sm={12} xs={12} className="about__col-right pb-5"></Col>
        </Row>
    </div>
  
    <Resource />
  </React.Fragment>
)
  };

export default AboutPage;
