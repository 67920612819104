import React, { useState, useEffect }  from "react";
import { Image, Col, Row, Card, Accordion, Button } from "react-bootstrap";
// import highSchoolLocker from "./images/high-school-locker.png";
// import dottedLine from "./images/data-dotted.png"
import "./CareerPage.css";
import CareerFilter from "../shared/career-filter/Career-Filter";
import GetStarted from "../shared/getstarted/GetStarted";
import Resource from "../shared/resource/Resource";
import ClusterTileEdu from "../shared/cluster-tile-edu/Cluster-tile-edu";
import Explore from "../shared/explore/Explore";
import axios from "axios";
import ag from "./images/Ag.png";
import architect from "./images/Architect.png";
import art from "./images/Art.png";
import business from "./images/Business.png";
import education from "./images/Education.png";
import finance from "./images/Finance.png";
import government from "./images/Government.png";
import health from "./images/Health.png";
import hospitality from "./images/Hospitality.png";
import human from "./images/Human.png";
import it from "./images/IT.png";
import law from "./images/Law.png";
import manufacturing from "./images/Manufacturing.png";
import marketing from "./images/Marketing.png";
import stem from "./images/Stem.png";
import transport from "./images/Transport.png";
import round_plus from "./images/Round_Plus.png";

const CareerPage = (data) => {
    
    const [] = useState(false);
    const [sectorClusterData, setSectorClusterData] = useState({});
    const [jobClusterData, setJobClusterData] = useState({});
    const [jobInfoData, setJobInfoData] = useState({});
    const [loadingInd, setLoadingInd] = useState({showLoading: true});
    const [loadingIndEdu, setLoadingIndEdu] = useState({showLoadingEdu: true});
    
    const tileUrl = `../tile-text.json`;   
    const jobUrl = `../job-info.json`;

    let getSectorClusters = () => {
        return axios.get(tileUrl);
    }

    let getJobs = () => {
        return axios.get(jobUrl);
    }

    useEffect(() => {
        axios.all([getSectorClusters(), getJobs()])
           .then(axios.spread(function (sector, job) {
               const sectorClusters = sector.data;
               const jobClusters = job.data;


               setSectorClusterData({ sectorClusters });
               setJobClusterData({ jobClusters });
               setLoadingInd({showLoading: false}); 
               setLoadingIndEdu({showLoadingEdu: false}); 
        }));
    }, []);

     let careerData = [];
     let jobData = [];
     let jobDataEduHS = 
     [{"topJobs" : [
        {
            "jobTitle": "No Data to Display",
            "jobLevel": "No Data to Display",
            "jobDescription": "No Data to Display",
            "jobSalary":"No Data to Display",
            "jobOpenings": "No Data to Display"
        }]}];
     
     let jobDataEdu2 = 
     [{"topJobs" : [
        {
            "jobTitle": "No Data to Display",
            "jobLevel": "No Data to Display",
            "jobDescription": "No Data to Display",
            "jobSalary":"No Data to Display",
            "jobOpenings": "No Data to Display"
        }]}];
     
     let jobDataEdu4 = 
     [{"topJobs" : [
        {
            "jobTitle": "No Data to Display",
            "jobLevel": "No Data to Display",
            "jobDescription": "No Data to Display",
            "jobSalary":"No Data to Display",
            "jobOpenings": "No Data to Display"
        }]}];

     let filterResponse = (rawClusterData, rawJobData, loading) => {
         if (loading.showLoading === true) {
             return (
             careerData = [
                 { 
                    "id": "0",
                    "sector" : "Loading",
                    "image" : "Loading",
                    "fact" : {
                        "factNumber": "Loading",
                        "factText": "Loading"
                    },
                        "clusterText" : "Loading"
                }
            ],

            jobData = [
                { 
                    "id": "0",
                    "sector" : "Loading",
                    "topJobs" : [
                            {
                                "jobTitle": "Loading...",
                                "jobLevel": "Loading...",
                                "jobDescription": "Loading...",
                                "jobSalary":"Loading...",
                                "jobOpenings": "Loading..."
                            }]
               }
           ]
             )
         } else {
             return (
                 careerData = rawClusterData.sectorClusters.filter(sectorCluster => sectorCluster.id === data.match.params.careerId),
                 jobData = rawJobData.jobClusters.filter(jobCluster => jobCluster.id === data.match.params.careerId)
             )
                 
         }
     } 

     let filterEduLevelResponse = (rawJobInfo, level, loadingEdu) => {
        if (loadingEdu.showLoadingEdu === false) {
            if(level === "HS"){
                return (
                    jobDataEduHS = rawJobInfo[0].topJobs.filter(topJob => topJob.jobLevel === level)
                )
            } else if(level === "2"){
                return (
                    jobDataEdu2 = rawJobInfo[0].topJobs.filter(topJob => topJob.jobLevel === level)
                )
            } else if(level === "4"){
                return (
                    jobDataEdu4 = rawJobInfo[0].topJobs.filter(topJob => topJob.jobLevel === level)
                )
            }
        } 
     }

     let spotlightJob = {jobData};

     const randomJob = (jobs) => {
        return spotlightJob = jobs[Math.floor(Math.random() * jobs.length)];
     }

     randomJob(jobData);

    // ---------------------
    // Uncomment if needed for any job
    // --------------------- 
    // const topJobHero = (jobHero, loading) => {
    //     if( jobHero === "14" && loading.showLoading === false){
    //         return jobDataEduHS[0].jobTitle
    //     } else if (jobHero !== "14" && loading.showLoading === false) {
    //             return jobDataEdu4[0].jobTitle        
    //     }
    // }
     
     let imageSwitch = (sectorData) => {
         switch(sectorData) {
                case "1":
                    return <Image className="hero-icon pr-5" src={ag} alt="" />;
                case "2":
                    return <Image className="hero-icon pr-5" src={marketing} alt="" />;    
                case "3":
                    return <Image className="hero-icon pr-5" src={stem} alt="" />;    
                case "4":
                    return <Image className="hero-icon pr-5" src={transport} alt="" />;    
                case "5":
                    return <Image className="hero-icon pr-5" src={architect} alt="" />;    
                case "6":
                    return <Image className="hero-icon pr-5" src={art} alt="" />;    
                case "7":
                    return <Image className="hero-icon pr-5" src={business} alt="" />;    
                case "8":
                    return <Image className="hero-icon pr-5" src={education} alt="" />;    
                case "9":
                    return <Image className="hero-icon pr-5" src={finance} alt="" />;    
                case "10":
                    return <Image className="hero-icon pr-5" src={government} alt="" />;    
                case "11":
                    return <Image className="hero-icon pr-5" src={health} alt="" />;    
                case "12":
                    return <Image className="hero-icon pr-5" src={hospitality} alt="" />;    
                case "13":
                    return <Image className="hero-icon pr-5" src={human} alt="" />;    
                case "14":
                    return <Image className="hero-icon pr-5" src={it} alt="" />;    
                case "15":
                    return <Image className="hero-icon pr-5" src={law} alt="" />;    
                case "16":
                    return <Image className="hero-icon pr-5" src={manufacturing} alt="" />;    
         }
     }

    
    filterResponse(sectorClusterData, jobClusterData, loadingInd);
    filterEduLevelResponse(jobData, "HS", loadingIndEdu);
    filterEduLevelResponse(jobData, "2", loadingIndEdu);
    filterEduLevelResponse(jobData, "4", loadingIndEdu);

    
     
    return(
        <React.Fragment>
        <div className="nav-background"></div>
        {data.dropDownInfo.tiles ? <Explore careerInfo = {data.dropDownInfo.tiles}/> : null}
        <div>
        <Row className="row-career__hero">
            <Col xs={12} sm={12} md={8} className="pt-5 pb-5 pl-5">
                <Row>
                    <Col xs={12} sm={12} md={12} lg={3} className="hero-icon__wrapper">
                        {imageSwitch(data.match.params.careerId)}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={9} className="hero-copy pl-5 pr-5">
                        
                        <h2 className="hero-copy__headline">{careerData[0].sector}</h2>
                        <p className="hero-copy__text">
                            {careerData[0].clusterText}
                        </p>  
                    </Col>
                </Row>
            </Col>
            <Col className="hero-data pt-5 pb-5" xs={12} sm={12} md={4}>
                <p className="hero-data__headline">DID YOU KNOW?</p>
                <Image src=""></Image>
                <p className="hero-data__salary pt-4 px-4">{jobDataEdu4[0].jobTitle}</p>
                <p className="hero-data__salary-primary-text pt-3">Top Career in {careerData[0].sector}</p>
            </Col>
        </Row>
    </div>
    {/* <div className="pt-5 pb-5 career-spotlight">
        <Row className="pb-4 get-started-text">
            <Col className="mx-auto">
                <h2 className="career-text-banner text-center">Career Spotlight</h2>
            </Col>
        </Row>
        <Row>
            <Col className="hero-data pt-5 pb-5" xs={12} sm={12} md={4}>
                <p className="hero-data__headline">DID YOU KNOW?</p>
                <Image src=""></Image>
                <p className="hero-data__salary pt-4 px-4">{jobDataEdu4[0].jobTitle}</p>
                <p className="hero-data__salary-primary-text pt-3">Top Career in {careerData[0].sector}</p>
            </Col>
            <Col>
                <h2 className="career-text-banner float-right">

                </h2>
            </Col>
        </Row>
    </div> */}
    <div className="education-background pb-5">
            <div className="pt-5 pb-2 home-text-banner">
                <h2>Jobs in Demand</h2>
                <p className="career-cluster-text">Check out these hot careers based on how far you 
                <br />
                want to take your education.</p>
            </div>
            <div className="tile-wrapper">
                <Row className="tile-row">
                    <ClusterTileEdu cluster="High School" jobInfo={jobDataEduHS} loadingIndicator={loadingIndEdu.showLoadingEdu}/>
                    <ClusterTileEdu cluster="2-Year" jobInfo={jobDataEdu2} loadingIndicator={loadingIndEdu.showLoadingEdu}/>
                </Row>
                <Row className="tile-row">
                    <ClusterTileEdu cluster="4-Year" jobInfo={jobDataEdu4} loadingIndicator={loadingIndEdu.showLoadingEdu}/>
                <div className="" id="cardContent">
                    <Card className="clusterCardTile">
                        <Accordion>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                <div className="row cluster-row">
                                    <div className="col-10">
                                    <div className="row">Military</div>
                                    </div>
                                    <div className="col-2">
                                        <Image src={round_plus} fluid></Image>
                                    </div>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body className="card--military">
                                <p className="card-military__copy pd-5">
                                    You could equip yourself with the right skill set for this job <br />
                                    if your career path involves service to our country. <br />
                                    Discover more about the U.S. armed forces. Hoo-ra.
                                </p>
                                    <Row className="military__buttons mt-5">
                                        <a href="https://www.goarmy.com/" target="_blank" rel="noopener noreferrer" ><Button variant="military" className="tile-military__buttons">ARMY</Button></a>
                                        <a href="https://www.navy.com/" target="_blank" rel="noopener noreferrer"><Button variant="military" className="tile-military__buttons">NAVY</Button></a>
                                        <a href="https://www.airforce.com/" target="_blank" rel="noopener noreferrer"><Button variant="military" className="tile-military__buttons">AIR FORCE</Button></a>
                                        <a href="https://www.marines.com/" target="_blank" rel="noopener noreferrer"><Button variant="military" className="tile-military__buttons">MARINES</Button></a>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                            </Accordion>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <Col className="text-center pt-4"><b>The positions displayed may require additional certifications or licenses.</b></Col>
                </Row>
            </div>
        </div>
        <GetStarted />
        <Resource />
    </React.Fragment>
    );
};

export default CareerPage;