import React, { useState, useEffect } from "react";
import "./Cluster-tile.css";
import { Image } from "react-bootstrap";
import { Card, Row, Col} from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import round_plus from "./images/Round_Plus.png";
import axios from "axios";
import { createImportSpecifier } from "typescript";


const ClusterTile = (data) => { 
   
    const [hovered, setHovered] = useState(false);
    const toggleHover = () => setHovered(!hovered);
    const [] = useState(false);
    const [jobClusterData, setJobClusterData] = useState({});
    const [jobInfoData, setJobInfoData] = useState({});
    const [loadingInd, setLoadingInd] = useState({showLoading: true});
    const [loadingIndEdu, setLoadingIndEdu] = useState({showLoadingEdu: true});

    const jobUrl = `../job-info.json`;

    let getJobs = () => {
        return axios.get(jobUrl);
    }    
    
    let jobData = [];

    let eduLevel = "";

    let jobDataEdu = 
     [{"topJobs" : [
        {
            "jobTitle": "No Data to Display",
            "jobLevel": "No Data to Display",
            "jobDescription": "No Data to Display",
            "jobSalary":"No Data to Display",
            "jobOpenings": "No Data to Display"
        }]}];

    useEffect(() => {
        axios.all([getJobs()])
           .then(axios.spread(function (job) {
               const jobClusters = job.data;

               setJobClusterData({ jobClusters });
               setLoadingInd({showLoading: false}); 
               setLoadingIndEdu({showLoadingEdu: false}); 
        }));
    }, []);

    const eduNormalize = (edu) => {
        switch(edu){
            case "1": 
                return eduLevel = "HS";
            case "2": 
                return eduLevel = "2";
            case "3": 
                return eduLevel = "4";        
            }

    }

    

        let filterResponse = (rawJobData, loading) => {
            if (loading.showLoading === true) {
                return (
                    jobData = [
                        { 
                            "id": "0",
                            "sector" : "Loading",
                            "topJobs" : [
                                    {
                                        "jobTitle": "Loading...",
                                        "jobLevel": "Loading...",
                                        "jobDescription": "Loading...",
                                        "jobSalary":"Loading...",
                                        "jobOpenings": "Loading..."
                                    }]
                    }
                ] )
            } else {
                return (
                    jobData = rawJobData.jobClusters.filter(jobCluster => jobCluster.sector === data.cluster.sector)
                )
                    
            }
        } 

        let filterEduLevelResponse = (rawJobInfo, level, loadingEdu) => {
            if (loadingEdu.showLoadingEdu === false) {
                    return (
                        jobDataEdu = rawJobInfo[0].topJobs.filter(topJob => topJob.jobLevel === level)
                    )
            } 
         }

    eduNormalize(data.edu);
    filterResponse(jobClusterData, loadingInd);
    filterEduLevelResponse(jobData, eduLevel, loadingIndEdu);

    return(
        <React.Fragment>
            <div className="" id="cardContent">
                <Card className="clusterCardTile" onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                    <Accordion>
                        <Accordion.Toggle as={Card.Header} className="cluster-card__title" eventKey="0">
                            <div className="row cluster-row">
                                <div className="col-10">
                                <div className="row">{data ? data.cluster.sector : "error"}</div>
                                </div>
                                <div className="col-2">
                                    <Image src={round_plus} fluid></Image>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Row>
                                    <Col xs={4} sm={{ span: 4 }}  className="cluster-tile__header">Sample Job</Col>
                                    <Col xs={4} sm={{ span: 4 }}  className="cluster-tile__header">Median Pay</Col>
                                    <Col xs={4}  sm={{ span: 4}}  className="cluster-tile__header">Job Openings</Col>
                                </Row>
                            {jobDataEdu.map((item, i) => {
                            return (
                                <Row key={i} value={item} className="cluster-tile__row">
                                     <Col xs={4} sm={{ span: 4 }} className="cluster-tile__column pr-2 pt-3">
                                        <div>
                                            <div className="tile__job-title" key={i} value={item.jobTitle}>{item.jobTitle}</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} sm={{ span: 4 }} className="cluster-tile__column pr-2 pt-3">
                                        <div>
                                            <div className="tile__job-salary" key={i} value={item.jobSalary}>{item.jobSalary}</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} sm={{ span: 4}} className="cluster-tile__column pr-2 pt-3">
                                       <div>
                                           <div className="tile__job-openings" key={i} value={item.jobOpenings}>{item.jobOpenings}</div>
                                        </div>
                                    </Col>
                                </Row>);
                                })
                            }        
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                </Card>
            </div>
        </React.Fragment>
    );
}

export default ClusterTile;
