import React, { useState, useEffect } from 'react';
import {Route, Switch} from "react-router-dom";
import HomePage from "./home/HomePage";
import AboutPage from "./about/AboutPage";
import ResourcesPage from "./resources/ResourcesPage";
import EducationPage from "./education/EducationPage";
import CareerPage from "./career/CareerPage";
import Header from "./shared/header/Header";
import './App.css';
import Footer from './shared/footer/Footer';
import axios from "axios";

function App() {
  
  const [] = useState(false);
  const [tileData, setTileData] = useState({});

  
  const url = '../tile-text.json';
  
  useEffect(() => {
      axios.get(url)
          .then(response => {
              const tiles = response.data;
              setTileData({ tiles });
          });
   }, []);

    return (
      <div className="container-fluid main">
        <Header />
        <Switch>
          <Route 
            exact path="/" 
            render={(props) => <HomePage {...props} tileInfo={tileData} /> }
            />
          <Route 
            path="/home" 
            render={(props) => <HomePage {...props} tileInfo={tileData} /> }
            />
          <Route path="/about" 
            render={(props) => <AboutPage {...props} dropDownInfo={tileData} /> }
            />
          <Route path="/resources" 
            render={(props) => <ResourcesPage {...props} dropDownInfo={tileData} /> }
            />
          <Route path="/education/:educationId" 
            render={(props) => <EducationPage {...props} dropDownInfo={tileData} /> }
            />
          <Route path="/career/:careerId" 
            render={(props) => <CareerPage {...props} dropDownInfo={tileData}/> }
          />
        </Switch>
        <Footer />
      </div>
    );
  }
  
export default App;
