import React from 'react';
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App";
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Helmet } from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';


const Title = 'ChamberRVA HireEd';

render(
      <BrowserRouter>
        <Helmet>
            <title>{ Title }</title>
        </Helmet>
        <App />
      </BrowserRouter>,
    document.getElementById("root")
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
