import React from "react";
import "./Resource.css";
import coach from "./images/Career_Coach_Online.png";
import invest from "./images/Invest_next.png";
import JA from "./images/JA_My_Way.png";
import { Card, Button } from "react-bootstrap";

function Resource(){ 
    return(
        <div className="resource__wrapper text-center">
            <div className="access__header pb-4">
                <p className="text-center mt-5 access__headline">
                    <h3>Access a World of Resources</h3>
                </p>
                <p className="text-center access__copy">
                    Check out these Richmond-area resources.<br />
                    that can guide you toward career success
                </p>
            </div>
            <div className="row justify-content-center">
                <Card className="card__resource card__full-height">
                    <Card.Body>
                        <Card.Title className="text-center"><img src={coach} className="resource-card-image mx-auto"/></Card.Title>
                        <Card.Text className="resource-text resource-card--home">
                        <span className="resource-title">
                        John Tyler Career Coach <br /></span>
                        <br />
                            <span className="resource-copy">Explore your career and <br />
                                educational interests.<br /></span>
                                <br />
                            <span className="resource-cta">FIND YOUR CAREER</span>
                        </Card.Text>
                        <a target="_blank" href="https://jtcc.emsicc.com/"><Button variant="view" rel="noopener noreferrer">View</Button></a>
                    </Card.Body>
                </Card>
                <Card className="ml-3 card__resource card__full-height">
                    <Card.Body>
                    <Card.Title className="text-center"><img src={JA} className="resource-card-image"/></Card.Title>
                        <Card.Text className="resource-text resource-card--home">
                            <span className="resource-title">
                            JA My Way<br /></span>
                            <br />
                            <span className="resource-copy">Get tips and tools to hop on the<br />
                                career fast track.<br /></span>
                            <br />
                        <span className="resource-cta">TIPS AND TOOLS</span>
                        </Card.Text>
                        <a target="_blank" href="https://www.jamyway.org/" rel="noopener noreferrer"><Button variant="view">View</Button></a>
                    </Card.Body>
                </Card>
                <Card  className="ml-3 card__resource card__full-height">
                    <Card.Body>
                        <Card.Title className="text-center"><img src={invest} className="resource-card-image"/></Card.Title>
                        <Card.Text className="resource-text resource-card--home">
                            <span className="resource-title">
                            Invest in What's Next<br /></span>
                            <br />
                            <span className="resource-copy">Discover your interests, budget<br />
                                and evaluate your plan.<br /></span>
                            <br />
                            <span className="resource-cta">COST CALCULATOR</span>
                        </Card.Text>
                        <a target="_blank" href="https://www.investinwhatsnext.org/" rel="noopener noreferrer"><Button variant="view">View</Button></a>
                    </Card.Body>
                </Card>
            </div>
            <div className="row justify-content-center mt-5 mb-5">
                <Button href="/Resources" variant="more">Get More</Button>
            </div>
        </div>
    );
}

export default Resource;