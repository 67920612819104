import React, { useState } from "react";
import "./Tile.css";
import { Image } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Plus from "./images/Plus_img.png";
import Hamburger from "./images/Menu_Hamburger.png";


const Tile = (data) => { 
   
    const [hovered, setHovered] = useState(false);
    const toggleHover = () => setHovered(!hovered);
    // const toggleHover = () => '';
    
    const hoverText = () => {
        if (!hovered && window.innerWidth > 900) {
            return (
            <React.Fragment>
                <div className="row">
                    <div className="mx-auto">
                        <Image src={(data.tile.image)}></Image>
                    </div>
                </div>
                <div className="row justify-content-center text-center card-data__front">
                    {data ? data.tile.sector : "error"}
                </div>
            </React.Fragment>
            )
        } else if (hovered && window.innerWidth > 900){
            return data ? <React.Fragment>
                    <div className="row justify-content-center tile-reverse-title">
                        {data ? data.tile.sector : "error"}
                    </div>
                    <div className="row tile-stat-number justify-content-center pt-2">
                        {data.tile.fact.factNumber}
                    </div>
                    <div className="row tile-stat-text justify-content-center word-wrap pt-2">
                        {data.tile.fact.factText}
                    </div>
                    <div className="learn-more__wrapper">
                        <Button variant="learn" className="card-back__learn-more" size="sm" href={`/career/${data.tile.id}` }>Learn More <Image src={Hamburger} /></Button>
                    </div>
                </React.Fragment> : "error";
        } else {
            return <div className="row justify-content-center text-center ">
                    {data ? data.tile.sector : "error"} 
                   </div>
        }
    }

    const hoverPlus = () => {
        if (!hovered) {
            return <div className="plus-button__image mx-auto">
            <Image src={Plus} className="tile-plus" thumbnail></Image>
            </div>; } else {
            return <div className="learn-more__wrapper">
            <Button variant="learn" className="card-back__learn-more" size="sm" id={`${data.tile.id}_${data.tile.sector.substring(0,3).toLowerCase()}`} href={`/career/${data.tile.id}` }>Learn More <Image src={Hamburger} /></Button>
        </div>;
            }
    }

    return(
        <React.Fragment>
            <div id="cardContent justify-content-center">
                <Card className={`${hovered ? "tile-reverse" : ""} cardTile text-center card__full-height`} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                    <Card.Body>
                        <Card.Text>
                        {hoverText()}
                        </Card.Text>
                        {hoverPlus()}
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
}

export default Tile;