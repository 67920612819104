import React from "react";
import "./Header.css";
import hireEdDark from "./images/HireED logo Dark ED.png";
import home from "./images/ico/24/ui/home@1x.png";
import resources from "./images/ico/24/ui/settings@1x.png";
import aboutLogo from "./images/about-logo.svg";
import hamburger from "./images/Menu_Hamburger.png";
import {Nav, Navbar, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';


const Header = () => (
    <div>
        <Navbar expand="sm">
            <Navbar.Brand href="/"><img src={hireEdDark} /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link href="/"><img src={home} width="16" height="16"/> Home <span className="sr-only">(current)</span></Nav.Link>
                    <Nav.Link href="/about"><img className="about-logo" src={aboutLogo} width="20" height="20"/>About <span className="sr-only">(current)</span></Nav.Link>
                    <Nav.Link href="/resources"><img src={resources} width="16" height="16"/> Resources <span className="sr-only">(current)</span></Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </div>
);

export default Header;